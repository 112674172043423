// import store from "@/state/store";

export default [
  /*{
    path: "/",
    name: "default",
    component: () => import("./views/dashboards/default"),
  },*/
  {
    path: "/",
    name: "Connexion",
    component: () => import("./views/account/login"),
    
  },
   {
    path: "/dashboard",
    name: "Tableau de bord",
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
   
  },
  {
    path: "/forgot-password",
    name: "Mot de pase oublié?",
    component: () => import("./views/account/forgot-password"),
   
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
   
  },

  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("./views/calendar/calendar"),
  },
  {
    path: "/settings",
    name: "Paramètres",
    component: () => import("./views/settings/Settings"),
  },
  {
    path: "/setting/state-services",
    name: "Service d'Etat",
    component: () => import("./views/settings/service/service"),
  },
  {
    path: "/setting/treatment-center",
    name: "Centre de Traitement",
    component: () => import("./views/settings/treatment/Treatment"),
  },
  {
    path: "/setting/treatment-officers",
    name: "Agents de Traitement",
    component: () => import("./views/settings/agent-treatment/Agent-treatment"),
  },
  {
    path: "/setting/intervention-officers",
    name: "Agents d'intervention",
    component: () => import("./views/settings/agents/Agent-intervention"),
  },
  {
    path: "/setting/intervention-officers/add",
    name: "Ajouter agent d'intervention",
    component: () => import("./views/settings/agents/AddAgent-intervention"),
  },
  {
    path: "/setting/treatment-officers/add",
    name: "Ajouter agent de traitement",
    component: () => import("./views/settings/agent-treatment/AddAgent-treatment"),
  },
  {
    path: "/setting/level-alert",
    name: "Paramétrer les niveaux d'alertes",
    component: () => import("./views/settings/alerte/Level-alerte"),
  },
  {
    path: "/setting/intervention-alert",
    name: "Paramétrer les interventions d'alertes",
    component: () => import("./views/settings/alerte/intervention-type"),
  },
  {
    path: "/setting/priorite-alert",
    name: "Paramétrer les priorités d'alertes",
    component: () => import("./views/settings/alerte/Priority-alerte"),
  },
  {
    path: "/setting/statut-alert",
    name: "Paramétrer les statuts d'alertes",
    component: () => import("./views/settings/alerte/Status-alerte"),
  }, {
    path: "/setting/theme-alert",
    name: "Paramétrer les Thématiques d'alertes",
    component: () => import("./views/settings/alerte/Theme-alerte"),
  },
  {
    path: "/maps",
    name: "Position des agents",
    component: () => import("./views/maps/index"),
  },
  {
    path: "/affect-officers",
    name: "Affecter un agent",
    component: () => import("./views/intervention/Affect-officers"),
  },
  {
    path: "/interventionProgress/:id",
    name: "Intervention en cours",
    component: () => import("./views/intervention/inProgress"),
  },
  {
    path: "/list-intervention",
    name: "liste des Interventions",
    component: () => import("./views/intervention/Liste"),
  },
  {
    path: "/launchIntervention/:id",
    name: "Lancer une Interventions",
    component: () => import("./views/intervention/launchIntervention"),
    props: true,
  },
  {
    path: "/createIntervention",
    name: "Créer une Interventions",
    component: () => import("./views/intervention/createIntervention"),
  },
  {
    path: "/list-incident",
    name: "liste des incident",
    component: () => import("./views/investigation/Liste"),
  },
  {
    path: "/detail-incident/:id",
    name: "Détails de'incident",
    component: () => import("./views/investigation/detailsIncident"),
  },
  {
    path: "/launch-incident",
    name: "Creer un incident",
    component: () => import("./views/investigation/Launch"),
  },
  {
    path: "/cartographie-incident",
    name: "Cartographie des incidents",
    component: () => import("./views/investigation/cartographie"),
  },
  {
    path: "/message-incident",
    name: "Message des incidents",
    component: () => import("./views/investigation/message"),
  },
  {
    path: "/zone-sensible/:id",
    name: "Details zone sensible",
    component: () => import("./views/investigation/zoneDetails"),
  },
  {
    path: "/add-zone",
    name: "Ajouter zone sensible",
    component: () => import("./views/investigation/add-zone"),
  },
  {
    path: "/stats-incidents",
    name: "Statistique des incidents",
    component: () => import("./views/statistiques/statIncident"),
  },
  {
    path: "/stats-alertes",
    name: "Statistique des alertes",
    component: () => import("./views/statistiques/statAlertes"),
  },
  {
    path: "/stats-interventions",
    name: "Statistique des interventions",
    component: () => import("./views/statistiques/statIntervention"),
  },
  {
    path: "/DelailPerson/:id",
    name: "personne recherchée",
    component: () => import("./views/personnes/datailsPerson"),
  },
  {
    path: "/liste-personne",
    name: "Liste personnes recherchées",
    component: () => import("./views/personnes/listePersonne"),
  },
  {
    path: "/add-personne",
    name: "Ajouter personnes recherchées",
    component: () => import("./views/personnes/addPersonne"),
  },
  {
    path: "/searchPersonneInfos",
    name: "Rechercher Personne",
    component: () => import("./views/personnes/searchByInfos"),
  },
  {
    path: "/VehiculeDelail/:id",
    name: "Vehicule recherchée",
    component: () => import("./views/vehicules/detailsVehicule"),
  },
  {
    path: "/liste-vehicule",
    name: "Liste Vehicules recherchées",
    component: () => import("./views/vehicules/listeVehicule"),
  },
  {
    path: "/add-vehicule",
    name: "Ajouter vehicules recherchées",
    component: () => import("./views/vehicules/addVehicule"),
  },
  {
    path: "/searchVehicule",
    name: "Recherché vehicules ",
    component: () => import("./views/vehicules/searchByInfos"),
  },
  {
    path: "/liste-contravention",
    name: "Liste des contraventions",
    component: () => import("./views/contravention/listeContravention"),
  },
  {
    path: "/details-contavention/:id",
    name: "Détails da la contravention",
    component: () => import("./views/contravention/detailsContravention"),
  },
  {
    path: "/stat-contravention",
    name: "Recette contravention",
    component: () => import("./views/contravention/StatContravention"),
  },
  {
    path: "/params-contravention",
    name: "Paramètre des contraventions",
    component: () => import("./views/contravention/paramContravention"),
  },
  {
    path: "/add-contravention",
    name: "Ajouter contravention",
    component: () => import("./views/contravention/addContravention"),
  },
  {
    path: "/lunchAlert",
    name: "Lancement d'alerte",
    component: () => import("./views/alertes/lunchAlertCtzn"),
  },
  {
    path: "/listAlert",
    name: "Liste d'alertes",
    component: () => import("./views/alertes/listAlertCtzn"),
  },
  {
    path: "/alertDelail/:id",
    name: "Détails d'alerte",
    component: () => import("./views/alertes/alertDetailsCtzn")
  },
  {
    path: "/alertReplies/:id",
    name: "Reponses d'alerte",
    component: () => import("./views/alertes/responseFromCtzn"),
  },
  {
    path: "/replyDetails/:id",
    name: "Details de Reponse",
    component: () => import("./views/alertes/responseDetails"),
  },
  {
    path: "/lunchAgentsAlert",
    name: "Lancement d'alerte agent",
    component: () => import("./views/alertesAgents/launchAlert"),
  },
  {
    path: "/agentAlertList",
    name: "Liste d'alertes agent",
    component: () => import("./views/alertesAgents/listAlerts"),
  },
  {
    path: "/editAgentAlert/:id",
    name: "Modification d'alerte agents",
    component: () => import("./views/alertesAgents/editAlert"),
  },
  {
    path: "/agentAlertDetail/:id",
    name: "Détails d'alerte agents",
    component: () => import("./views/alertesAgents/alertDetails"),
  },
  {
    path: "/agentsResponses/:id",
    name: "Reponses d'alertes Agent",
    component: () => import("./views/alertesAgents/responses"),
  },
  {
    path: "/agentsResponsDetail/:id",
    name: "Détails de réponse d'agent",
    component: () => import("./views/alertesAgents/responseDetails"),
  },
  {
    path: "/receivedAlerts",
    name: "Alertes reçus",
    component: () => import("./views/receivedAlertes/receivedAlert"),
  },
  {
    path: "/receivedAlertDetail/:id",
    name: "Detail d'alerte reçu",
    component: () => import("./views/receivedAlertes/receivedAlertDetail.vue"),
  },
  {
    path: "/sosListe",
    name: "Liste des SOS",
    component: () => import("./views/sos/receivedSos"),
  },
  {
    path: "/sosDetail/:id",
    name: "Detail de SOS",
    component: () => import("./views/sos/sosDetails"),
  },
  {
    path: "/sosUpdates/:id",
    name: "SOS Updates",
    component: () => import("./views/sos/soSupdates"),
  },
];
