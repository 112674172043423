import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import DropZone from 'dropzone-vue';
import {registerScrollSpy} from 'vue3-scroll-spy';
import Maska from 'maska'
import {i18n} from "./i18n.js"

import VueFileAgentNext from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import VueLazyLoad from 'vue-lazyload'

import store from "./state/store";
// import store from "./store";

import "../src/design/app.scss";
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
    .use(store)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(Maska)
    .use(DropZone)
    .use(VueFileAgentNext)
    .use(VueViewer)
    .use(VueLazyLoad)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAE7XxpQDMYigrSs3NWWDNbNDKXEMcf4IE',
            language: 'fr',
        },
        autobindAllEvents: true,
    })
    .mount('#app')
