const axios = require('axios');
import hmacSHA256 from 'crypto-js/hmac-sha256';
import encBase64 from 'crypto-js/enc-base64';
axios.default.withCredentials = true;
const SecretKey = "1Bw$K3pLQ@p5vYoD8m^ijHnNlGy0tVf3Sr$A#o?gJ!xUzWqI9c*ZeXhEa4Mb&u7d"

const generateSignature = (message, secretKey) => {
    const hash = hmacSHA256(message, secretKey);
    return encBase64.stringify(hash);
};

const verifySignature = (content, receivedSignature, secretKey) => {
    const calculatedSignature = generateSignature(content, secretKey);
    return receivedSignature === calculatedSignature;
};

const instance = axios.create({ baseURL: 'https://api.alcit.sims-technologie.com/api/v1' });

instance.interceptors.request.use((config) => {
    const data = JSON.stringify(config.data || '');
    const token = localStorage.getItem('token');
   /* const signature = generateSignature(data, SecretKey);
    config.headers['X-Signature'] = signature;*/
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Vérification de la signature de la réponse
    /*const data = JSON.stringify(response.data || '');
    const receivedSignature = response.headers['X-Signature'];
    const calculatedSignature = generateSignature(data, SecretKey);

    if (receivedSignature !== calculatedSignature) {
        return Promise.reject(new Error('La signature de la réponse est invalide'));
    }*/

    return response;
}, function (error) {
    return Promise.reject(error);
});


export const state = {
    authenticated: null,
}

export const getters = {
    getloggedUser: state => state.user,
    getUserState: state => state.authenticated,
}

export const mutations = {
    SET_USER_STATUS(state, authenticated) {
        state.authenticated = authenticated;
    },
    SET_USER(state, user) {
        state.user = user;
    },
}

export const actions = {
    setLoggedUser({ commit }, { user }) {
        commit('SET_USER', user)
    },
    changeUserStatus({ commit }, { authenticated }) {
        commit('SET_USER_STATUS', authenticated);
    },

    postRequest(commit, { route, data }) {
        return new Promise((resolve, reject) => {
            instance.post(route, data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    },
      
    putRequest(commit, { route, data }) {
        return new Promise((resolve, reject) => {
            instance.put(route, data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    },

    deleteRequest(commit, { route, id } ) {
        return new Promise((resolve, reject) => {
            instance.delete(route, id)
            .then(
                function (response) {
                resolve(response)
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },

    getRequest(commit, route) {
        return new Promise((resolve, reject) => {
            instance.get(route)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    },
}
